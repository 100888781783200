import React from "react";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {ExampleCodeSnippet} from "../../components/snippet";

const pageTitle = "Use Native API";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            Using native DBMS API requires including the appropriate DBMS API
            header file (comes with SQLAPI++). Native DBMS methods are accessible
            just after SQLAPI++ database connection is initialized.
        </p>

        <p>
            Example below illustrates usage of native API by showing how to create Firebird database with SQLAPI++ using
            native Firebird {' '}<code>isc_dsql_execute_immediate</code>{' '} call:
        </p>
        <ExampleCodeSnippet filename="native_api.cpp"/>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
